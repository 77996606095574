import { Box, Button, Image, Text } from "@chakra-ui/core"
import React from "react"

type PostThumbnailProps = {
  title: string
  cover: string
  intro: string
  published: string
  uid: string
}
export const PostThumbnail = ({
  title,
  cover,
  intro,
  published,
  uid,
}: PostThumbnailProps) => (
  <Box mb={5} borderWidth="1px" borderRadius={3} borderColor="gray.300">
    <Image
      src={cover}
      h={["200px", "300px", "400px"]}
      w="100%"
      objectFit="cover"
    />
    <Box p={5}>
      <Text color="green.700" fontSize="sm">
        {published}
      </Text>
      <Text fontSize="2xl" mb={3} color="gray.700">
        {title}
      </Text>
      <Text fontSize="lg" color="gray.600">
        {intro}
      </Text>
      <Button as="a" href={`/blog/${uid}`} variantColor="green" my={5}>
        Olvass tovább
      </Button>
    </Box>
  </Box>
)
