import React, { FunctionComponent } from "react"

import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { Countdown } from "../components/countdown"
import { LatestPosts } from "../components/blog/latest-posts"

const IndexPage: FunctionComponent<{ data: any }> = ({ data }) => {
  const blogposts = data.allPrismicBlogposts.edges
  const totalCount = data.allPrismicBlogposts.totalCount

  return (
    <Layout>
      <SEO title="Kezdőlap" />
      <Countdown />
      <LatestPosts posts={blogposts} showMoreButton={totalCount > 4} />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    allPrismicBlogposts(
      sort: { fields: data___published_at, order: DESC }
      limit: 4
    ) {
      totalCount
      edges {
        node {
          id
          first_publication_date(formatString:"YYYY. MM. DD.")
          last_publication_date
          data {
            published_at(formatString:"YYYY. MM. DD.")
            title {
              text
            }
            intro {
              text
            }
            cover {
              url
            }
            content {
              text
            }
          }
        }
      }
    }
  }
`
export default IndexPage
