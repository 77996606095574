import { Box, Text, Grid, Button } from "@chakra-ui/core"
import React from "react"
import { PostThumbnail } from "./post-thumbnail"
import { Title } from "../title"

type LatestPostsProps = { posts: {}[]; showMoreButton: boolean }
export const LatestPosts = ({ posts, showMoreButton }: LatestPostsProps) => {
  return (
    <>
      <Box px={3} mt={5} mb={5}>
        <Title text="Legutóbbi bejegyzések" />
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
          columnGap={3}
          rowGap={3}
        >
          {posts?.map((blogpost: any, index: number) => {
            const { data, id, first_publication_date } = blogpost.node
            return (
              <PostThumbnail
                key={index}
                title={data.title?.text}
                cover={data.cover.url}
                published={data.published_at}
                intro={data.intro?.text}
                uid={id}
              />
            )
          })}
        </Grid>
      </Box>
      {showMoreButton && (
        <Button
          as="a"
          href="/blog"
          alignSelf="center"
          variantColor="green"
          size="lg"
          mb={6}
        >
          További bejegyzések
        </Button>
      )}
    </>
  )
}
