import { Box, Text, SimpleGrid } from "@chakra-ui/core"
import React, { useState, useEffect } from "react"
import { Container } from "../container"
import { Counter } from "./counter"
import { graphql, useStaticQuery } from "gatsby"
import { Title } from "../title"
import {
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns"

export const Countdown = () => {
  const [now, setNow] = useState(new Date())

  const data = useStaticQuery(graphql`
    query Race {
      allPrismicRaces {
        nodes {
          data {
            datetime(formatString: "YYYY. MM. DD.")
            title {
              text
            }
          }
        }
      }
    }
  `)

  if (!data.allPrismicRaces) return null
  const currentRace = data.allPrismicRaces.nodes.filter(r => {
    if (r.data.datetime) {
      const year = r.data.datetime.split(".")[0]
      const month = r.data.datetime.split(".")[1]
      const day = r.data.datetime.split(".")[2]

      return new Date() < new Date(year, month - 1, day)
    }
  })
  if (currentRace.lenght == 0) return null

  if (!currentRace[0]) return null
  const race = currentRace[0].data
  const year = race.datetime.split(".")[0]
  const month = race.datetime.split(".")[1]
  const day = race.datetime.split(".")[2]

  React.useEffect(() => {
    const timer = setInterval(() => setNow(new Date()), 1000)
    return () => clearInterval(timer)
  }, [now])

  const date = new Date(year, month - 1, day)

  return (
    <Box py={3} color="gray.700">
      <Container>
        <Title text="A VERSENY" />

        <Text textAlign="center" fontSize="xl">
          {race.datetime}
        </Text>
        <SimpleGrid columns={[1, 4]} mt={8}>
          <Counter
            text="NAP"
            maxValue={365}
            value={differenceInDays(date, now)}
          />
          <Counter
            text="ÓRA"
            maxValue={24}
            value={differenceInHours(date, now) % 24}
          />
          <Counter
            text="PERC"
            maxValue={60}
            value={differenceInMinutes(date, now) % 60}
          />
          <Counter
            text="MÁSODPERC"
            maxValue={60}
            value={differenceInSeconds(date, now) % 60}
          />
        </SimpleGrid>
      </Container>
    </Box>
  )
}
