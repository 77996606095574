import {
  Box,
  Text,
  CircularProgress,
  CircularProgressLabel,
  Flex,
} from "@chakra-ui/core"
import React from "react"

type counterProps = {
  text: string
  maxValue: number
  value: number
}
export const Counter = ({ text, maxValue, value }: counterProps) => (
  <Flex
    py={3}
    w="100%"
    color="gray.700"
    flexDirection="column"
    alignItems="center"
  >
    <CircularProgress
      value={(value / maxValue) * 100}
      color="green"
      size={["96px", "120px", "135px", "196px"]}
    >
      <CircularProgressLabel>{value}</CircularProgressLabel>
    </CircularProgress>
    <Text textAlign="center" fontSize={["xl", "2xl", "3xl"]} fontWeight="bold" mt={3}>
      {text}
    </Text>
  </Flex>
)
